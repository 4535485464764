a {
  text-decoration: none;
}
.news-card {
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.news-card:hover {
  transform: scale(1.02);
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

.news-image {
  height: 100%;
  object-fit: cover;
}

.news-title {
  font-weight: bold;
  font-size: 1.5rem;
}

.news-description {
  color: #555;
  margin: 15px 0;
}

.similar-articles {
  padding-left: 20px;
  list-style-type: disc;
}

.similar-link {
  color: #007bff;
  text-decoration: none;
}

.similar-link:hover {
  text-decoration: underline;
  color: #0056b3;
}
